/* eslint-disable import/newline-after-import */
/* eslint-disable eqeqeq */
/* eslint-disable arrow-parens */
/* eslint-disable comma-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable no-undef */
/* eslint-disable semi */
/* eslint-disable indent */
/* eslint-disable quotes */
// axios
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      deleteResult: true,
    };
  },
  methods: {
    getImage(width, height) {
      return "https://placehold.co/" + width + "x" + height;
    },
    async callApi(method, url, dataObj) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.token;
      try {
        return await axios({
          method: method,
          url: url,
          data: dataObj,
        });
      } catch (e) {
        this.$Loading.error();
        return e.response;
      }
    },
    DeleteAlert(url, dataObj) {
      this.$Loading.start();
      axios
        .post(url, dataObj)
        .then((res) => {
          if (res.status == 200) {
            this.$Loading.finish();
            this.s("Data Successfully Deleted :)");
          } else {
            this.$Loading.error();
            this.sw();
          }
        })
        .catch((e) => {
          this.$Loading.error();
          this.sw();
        });
    },
    i(description, title = "Hey!") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: description,
          variant: "info",
        },
      });
    },
    s(description, title = "Great!") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: description,
          variant: "success",
        },
      });
    },
    w(description, title = "Oops!") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: description,
          variant: "warning",
        },
      });
    },
    e(description, title = "Hey!") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: description,
          variant: "danger",
        },
      });
    },
    sw(title = "Oops!") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: "Something was wrong",
          variant: "danger",
        },
      });
    },

    buildQueryParamString(search) {
      const queryParams = [];
      for (const key in search) {
        if (
          search[key] !== undefined &&
          search[key] !== "" &&
          search[key] !== null
        ) {
          if (Array.isArray(search[key])) {
            // Handle array values
            search[key].forEach((value) => {
              queryParams.push(`${key}[]=${encodeURIComponent(value)}`);
            });
          } else {
            queryParams.push(`${key}=${encodeURIComponent(search[key])}`);
          }
        }
      }
      return queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
    },
  },
};
