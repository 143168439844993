import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import permission from "@/store/permission";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    base_url: "https://skillsapi.citsmp.com/",
    token: localStorage.getItem("token") || null,
    user_name: localStorage.getItem("user_name") || null,
    role_name: localStorage.getItem("role_name") || null,

    getMainCategory: [],
    getTeacher: [],
    getSubCategory: [],
    getActiveCourses: [],
    getOptionCategory: [],
    getCourseOptions: [],
    getCategoryWiseCourses: [],
    getHomePageOptions: [],
    getMainCategoryWiseSubCategory: [],
    getPermissions: {},
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    getMainCategory(state) {
      return state.getMainCategory;
    },
    getTeacher(state) {
      return state.getTeacher;
    },
    getSubCategory(state) {
      return state.getSubCategory;
    },
    getActiveCourses(state) {
      return state.getActiveCourses;
    },
    getOptionCategory(state) {
      return state.getOptionCategory;
    },
    getCourseOptions(state) {
      return state.getCourseOptions;
    },
    getCategoryWiseCourses(state) {
      return state.getCategoryWiseCourses;
    },
    getHomePageOptions(state) {
      return state.getHomePageOptions;
    },
    getMainCategoryWiseSubCategory(state) {
      return state.getMainCategoryWiseSubCategory;
    },
    getPermissions(state) {
      return state.getPermissions;
    },
  },
  mutations: {
    setToken(state, data) {
      state.token = data.token;
      state.user_name = data.name;
      state.role_name = data.role_name;
    },
    removeToken(state) {
      state.token = null;
      state.user_name = null;
      state.role_name = null;
    },
    getMainCategory(state, data) {
      return (state.getMainCategory = data);
    },
    getTeacher(state, data) {
      return (state.getTeacher = data);
    },
    getSubCategory(state, data) {
      return (state.getSubCategory = data);
    },
    getActiveCourses(state, data) {
      return (state.getActiveCourses = data);
    },
    getOptionCategory(state, data) {
      return (state.getOptionCategory = data);
    },
    getCourseOptions(state, data) {
      return (state.getCourseOptions = data);
    },
    getCategoryWiseCourses(state, data) {
      return (state.getCategoryWiseCourses = data);
    },
    getHomePageOptions(state, data) {
      return (state.getHomePageOptions = data);
    },
    getMainCategoryWiseSubCategory(state, data) {
      return (state.getMainCategoryWiseSubCategory = data);
    },
    getPermissions(state, data) {
      return (state.getPermissions = data);
    },
  },
  actions: {
    removeToken(context) {
      context.commit("removeToken");
    },
    setToken(context, data) {
      context.commit("setToken", data);
    },

    CheckAuth(content) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/check")
        .then((res) => {
          if (!res.data.status) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        });
    },

    getMainCategory(content) {
      axios
        .post("/app/course/categories/get/all")
        .then((res) => {
          content.commit("getMainCategory", res.data.categories);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getTeacher(content) {
      axios
        .post("/app/user/teacher/get")
        .then((res) => {
          content.commit("getTeacher", res.data.teachers);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getSubCategory(content) {
      axios
        .post("/app/course/sub/categories/get/all")
        .then((res) => {
          content.commit("getSubCategory", res.data.categories);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getMainCategoryWiseSubCategory(content, id) {
      axios
        .post("/app/course/category/wise/sub/category/" + id)
        .then((res) => {
          content.commit("getMainCategoryWiseSubCategory", res.data.categories);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getActiveCourses(content) {
      axios
        .post("/app/course/get/active/courses")
        .then((res) => {
          content.commit("getActiveCourses", res.data.courses);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getOptionCategory(content) {
      axios
        .get("/app/cms/option/category")
        .then((res) => {
          content.commit("getOptionCategory", res.data.categories);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCourseOptions(content) {
      axios
        .get("/app/cms/get/course/options")
        .then((res) => {
          content.commit("getCourseOptions", res.data.options);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getHomePageOptions(content) {
      axios
        .get("/app/cms/get/home/page/options")
        .then((res) => {
          content.commit("getHomePageOptions", res.data.options);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCategoryWiseCourses(content, id) {
      axios
        .get("/app/course/get/category/course/" + id)
        .then((res) => {
          content.commit("getCategoryWiseCourses", res.data.courses);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    permission,
  },
  strict: process.env.DEV,
});
